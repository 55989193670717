@if (audioMessage) {
	<div class="audio-container">
		<div
			class="fx-layout-row-nowrap fx-content-start fx-items-center fx-gap-4">
			<button (click)="playPause()" mat-icon-button style="display: flex">
				<mat-icon
					[class.seen]="!!audioMessage.seenDateTime"
					[fontIcon]="playing ? 'mdi-pause' : 'mdi-play'"
					fontSet="mdi"
					style="font-size: 32px !important"></mat-icon>
			</button>
			<mat-slider
				[max]="audioDuration"
				[min]="0"
				color="primary"
				[step]="0.1"
				style="width: 180px"
				#ngSlider
				><input
					matSliderThumb
					[value]="currentTime"
					(change)="
						setProgress({
							source: ngSliderThumb,
							parent: ngSlider,
							value: ngSliderThumb.value
						})
					"
					#ngSliderThumb="matSliderThumb" />
			</mat-slider>
			<div
				[style.backgroundColor]="audioMessage.sender.color"
				class="audio-head">
				{{ audioMessage.sender.name | initials }}
			</div>
			<mat-icon
				[class.seen]="!!audioMessage.seenDateTime"
				class="seen-status"
				fontIcon="mdi-microphone"
				fontSet="mdi"></mat-icon>
		</div>
		<div class="message-time fx-layout-row-nowrap fx-content-space-between">
			<span>{{ audioLength }}</span>
			<span>{{ audioMessage.sentDateTime | date: 'HH:mm' }}</span>
		</div>
	</div>
}
