import { MessageType } from './message-type';
import { ChatUser } from './chat-user';

export class Message {
	constructor(
		public id?: number,
		public messageType: MessageType = MessageType.TEXT,
		public data?: any,
		public text: string = undefined,
		public sender: ChatUser = undefined,
		public receiver: ChatUser = undefined,
		public sentDateTime: any = new Date(),
		public seenDateTime: any = undefined,
		public deleted?: boolean,
		public roomId?: number,
		public duration?: number,
		public deletedFor: any = null,
		public replyTo?: Message
	) {}

	static copyData(from: Message): Message {
		const msg = new Message();
		msg.data = from.data;
		msg.text = from.text;
		msg.messageType = from.messageType;
		return msg;
	}
}
