import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChatUser, MessageSource } from '../models';
import { MessagingService } from '../messaging.service';
import { deleteItemFromArray } from '../../shared';

@Component({
	selector: 'ft-user-select',
	templateUrl: './user-select.component.html',
	styleUrls: ['./user-select.component.scss'],
})
export class UserSelectComponent implements OnInit {
	public selection: ChatUser[] = [];

	public contacts: ChatUser[] = [];
	public source: MessageSource = MessageSource.NEW;

	constructor(
		@Inject(MAT_DIALOG_DATA) private data: any,
		private _dialogRef: MatDialogRef<UserSelectComponent>,
		private _service: MessagingService
	) {
		this.source = this.data.source;
	}

	ngOnInit(): void {
		this._service
			.getContacts(this.data.userId)
			.subscribe(value => (this.contacts = value));
	}

	selectContacts(contact: ChatUser) {
		if (this.selection.find(it => it.userId === contact.userId))
			deleteItemFromArray(this.selection, contact);
		else this.selection.push(contact);

		if (this.source === MessageSource.NEW) this.ok();
	}

	ok() {
		this._dialogRef.close(this.selection);
	}
}
