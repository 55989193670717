<input
	#fileUpload
	(change)="onFileSelected($event)"
	class="file-input"
	type="file" />
<div
	class="fx-layout-row fx-content-center fx-items-center fx-fill fx-bg-transparent">
	<div
		class="messaging-container fx-layout-row-nowrap fx-content-space-between">
		<mat-menu #roomMenu="matMenu" overlapTrigger="true">
			<button (click)="deleteRoom(roomFromMenu)" mat-menu-item>
				{{ 'DELETE' | translate }}
			</button>
		</mat-menu>

		<!--    Side nav-->
		<div
			class="messaging-sidenav fx-hide-lt-sm fx-layout-column fx-content-start fx-items-stretch"
			style="width: 20%">
			<div
				class="fx-layout-row-nowrap fx-content-start fx-items-center fx-height-48">
				<input
					(input)="filterRooms($event)"
					[placeholder]="'SEARCH' | translate"
					class="fx-grow-1"
					type="search" />
			</div>
			<div class="contacts fx-grow-1 fx-overflow-auto">
				@for (room of filteredRooms; track room) {
					<div
						(click)="openRoom(room)"
						[class.active-room]="room?.id === selectedRoom?.id"
						class="contact fx-padding-8 fx-layout-row-nowrap fx-content-start fx-items-center fx-gap-8">
						<div
							[style.backgroundColor]="getContact(room)?.color"
							[matBadge]="
								selectedRoom?.id === room.id ||
								room.lastMessage?.sender.id === me.id
									? null
									: room.unread
							"
							class="chat-head fx-layout-row fx-content-center fx-items-center"
							matBadgeColor="warn"
							aria-hidden="false"
							[matBadgeOverlap]="true"
							matBadgeSize="small">
							{{ getContact(room)?.name | initials }}
						</div>
						<div class="chat-text">
							<div
								class="contact-name fx-layout-row-nowrap fx-content-space-between">
								{{ getContact(room)?.name }}
								<span class="fx-grow-1"></span>
								<span class="last-update">{{
									room?.lastMessage?.sentDateTime
										| date: 'dd/MM/yyyy'
								}}</span>
							</div>
							<div class="room-last-message">
								@if (!typing[getContact(room).userId]) {
									@switch (room.lastMessage?.deletedFor) {
										@case ('ALL') {
											<div class="message-deleted">
												<mat-icon
													fontIcon="mdi-cancel"
													fontSet="mdi"></mat-icon>
												{{
													'MESSAGE_DELETED'
														| translate
												}}
											</div>
										}
										@case (me.userId) {
											<div class="message-deleted">
												<mat-icon
													fontIcon="mdi-cancel"
													fontSet="mdi"></mat-icon>
												{{
													'MESSAGE_DELETED'
														| translate
												}}
											</div>
										}
										@default {
											<div
												class="fx-layout-row-nowrap fx-content-start fx-items-center">
												@if (
													room.lastMessage &&
													messageIsMine(
														room.lastMessage
													)
												) {
													<mat-icon
														[class.seen]="
															room.lastMessage
																?.seenDateTime
														"
														[fontIcon]="
															room.lastMessage
																?.seenDateTime
																? 'mdi-check-all'
																: 'mdi-check'
														"
														class="message-status"
														fontSet="mdi"></mat-icon>
												}

												<div
													[innerHTML]="
														room.lastMessage
															?.messageType ===
														'TEXT'
															? (room.lastMessage
																	?.text
																| emojisDisplay)
															: (room.lastMessage
																	?.messageType
																| translate)
													"
													class="fx-layout-row-nowrap fx-content-start fx-items-center"></div>
											</div>
										}
									}
								} @else {
									<div class="typing">
										{{ 'TYPING' | translate }}
									</div>
								}
							</div>
						</div>
						<button
							(click)="
								$event.stopPropagation(); roomFromMenu = room
							"
							[matMenuTriggerFor]="roomMenu"
							mat-icon-button>
							<mat-icon
								fontIcon="mdi-chevron-down"
								fontSet="mdi"></mat-icon>
						</button>
					</div>
				}
			</div>

			<div
				class="fx-layout-row-nowrap fx-content-center fx-items-center fx-gap-12 fx-height-48">
				<button (click)="newMessage()" color="primary" mat-button>
					<mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
					{{ 'NEW_CHAT' | translate }}
				</button>
			</div>
		</div>

		<!--    Container-->
		@if (selectedRoom) {
			<div class="messaging-content fx-grow-1 fx-layout-column-nowrap">
				@if (!searching) {
					<div
						class="fx-layout-row-nowrap fx-content-start fx-items-center fx-gap-8 fx-height-48">
						<div
							[style.backgroundColor]="currentContact?.color"
							class="chat-head fx-layout-row fx-content-center fx-items-center">
							{{ currentContact?.name | initials }}
						</div>
						<div class="chat-text">
							<div
								[style.fontWeight]="'bold'"
								class="contact-name">
								{{ currentContact?.name }}
							</div>
							@if (
								currentContact?.online &&
								!typing[currentContact.userId]
							) {
								<div>
									<mat-icon
										class="online-status"
										fontIcon="mdi-circle"
										fontSet="mdi"></mat-icon>
									{{ 'ONLINE' | translate }}
								</div>
							}
							@if (typing[currentContact.userId]) {
								<div class="typing">
									{{ 'TYPING' | translate }}
								</div>
							}
						</div>
						<span class="fx-grow-1"></span>
						<mat-menu #chatMenu="matMenu" overlapTrigger="true">
							<button [disabled]="true" mat-menu-item>
								{{ 'CHAT_MEDIA' | translate }}
							</button>
							<button
								(click)="
									selectionActivated = !selectionActivated;
									selectedMessages = []
								"
								mat-menu-item>
								{{
									(selectionActivated
										? 'UNSELECT_ALL'
										: 'SELECT_MESSAGES'
									) | translate
								}}
							</button>
						</mat-menu>
						<button
							(click)="searching = true"
							[matTooltip]="'SEARCH' | translate"
							mat-icon-button>
							<mat-icon
								fontIcon="mdi-magnify"
								fontSet="mdi"></mat-icon>
						</button>
						<button [matMenuTriggerFor]="chatMenu" mat-icon-button>
							<mat-icon
								fontIcon="mdi-dots-vertical"
								fontSet="mdi"></mat-icon>
						</button>
					</div>
				} @else {
					<div
						class="message-search-container fx-layout-row-nowrap fx-content-start fx-items-center fx-height-48">
						<mat-icon
							fontIcon="mdi-magnify"
							fontSet="mdi"></mat-icon>
						<input
							(input)="filterMessages($event)"
							[placeholder]="'SEARCH' | translate"
							type="search" />
						<button (click)="searching = false" mat-icon-button>
							<mat-icon
								fontIcon="mdi-close"
								fontSet="mdi"></mat-icon>
						</button>
					</div>
				}

				<div
					class="messages-feed fx-grow-1 fx-layout-column-nowrap fx-content-start fx-gap-4"
					id="message-feed">
					<div
						class="fx-layout-row-nowrap fx-content-center fx-items-center">
						@if (isLoading) {
							<mat-spinner
								[diameter]="30"
								[strokeWidth]="3"
								color="primary"></mat-spinner>
						}
					</div>

					@for (
						group of currentMessages | groupBy: 'sentDateTime';
						track trackFn
					) {
						<div>
							<div
								class="fx-layout-row-nowrap fx-content-center fx-items-center">
								<span class="date-group">{{
									group.key | date: 'dd/MM/yyyy'
								}}</span>
							</div>

							@for (message of group?.values; track message) {
								<div
									class="message-box fx-layout-row-nowrap"
									[class.fx-content-end]="
										message.sender?.id === me.id
									"
									[class.fx-content-start]="
										message.sender?.id !== me.id
									">
									@if (selectionActivated) {
										<mat-checkbox
											(change)="
												selectMessage(
													message,
													$event.checked
												)
											"
											[labelPosition]="
												message?.sender.id === me.id
													? 'before'
													: 'after'
											"
											color="primary">
											<!-- Message data -->
											<div
												[class.right]="
													messageIsMine(message)
												"
												class="message">
												@if (
													message.replyTo &&
													!message.deleted
												) {
													<div
														[style.borderLeftColor]="
															'#35cd96'
														"
														class="reply-to-msg fx-layout-row-nowrap fx-content-start fx-gap-4">
														<div
															[class.horizontal]="
																message.replyTo
																	.messageType !==
																'TEXT'
															">
															<div
																[style.color]="
																	'#34b7f1'
																">
																<span
																	[style.fontWeight]="
																		'bold'
																	"
																	>{{
																		message
																			.replyTo
																			.sender
																			.id ===
																		me.id
																			? ('Me'
																				| translate)
																			: message
																					.replyTo
																					.sender
																					.name
																	}}</span
																>
																@if (
																	message
																		.replyTo
																		.messageType !==
																	'TEXT'
																) {
																	<div
																		class="msg-type">
																		<mat-icon
																			[fontIcon]="
																				getIcon(
																					message
																						.replyTo
																						.messageType
																				)
																			"
																			fontSet="mdi"></mat-icon>
																		@if (
																			message
																				.replyTo
																				.messageType ===
																			'AUDIO'
																		) {
																			<span
																				>{{
																					getAudioDuration(
																						message
																							.replyTo
																							.duration
																					)
																				}}</span
																			>
																		} @else {
																			<span
																				>•
																				{{
																					replyToMessage.messageType
																						| translate
																				}}</span
																			>
																		}
																		<ng-template
																			#otherRef>
																			<span
																				>•
																				{{
																					message
																						.replyTo
																						.messageType
																						| translate
																				}}</span
																			>
																		</ng-template>
																	</div>
																}
															</div>

															@switch (
																message.replyTo
																	.messageType
															) {
																@case (
																	'IMAGE'
																) {
																	<div
																		(click)="
																			openImage(
																				message.replyTo
																			)
																		"
																		class="image-message">
																		<img
																			[src]="
																				message
																					.replyTo
																					.data
																			"
																			alt=""
																			height="40px"
																			width="40px" />
																	</div>
																}
																@case (
																	'AUDIO'
																) {
																	<div
																		class="audio-message"></div>
																}
																@case (
																	'EMOJI'
																) {
																	<div
																		class="emoji-message"></div>
																}
																@case ('FILE') {
																	<div
																		class="file-message">
																		<div
																			class="fx-layout-row fx-content-space-between fx-items-center fx-gap-4">
																			<mat-icon
																				style="
																					color: darkslategrey;
																				"
																				fontSet="mdi"
																				fontIcon="mdi-file"></mat-icon>
																			<span
																				>{{
																					message.text
																				}}</span
																			>
																		</div>
																	</div>
																}
																@case (
																	'VIDEO'
																) {
																	<div
																		class="video-message"></div>
																}
																@default {
																	<div
																		class="text-message fx-layout-row fx-content-start fx-items-center">
																		{{
																			message
																				.replyTo
																				.text
																		}}
																	</div>
																}
															}
														</div>
													</div>
												}

												@switch (message.deletedFor) {
													@case ('ALL') {
														<div
															class="message-deleted">
															<mat-icon
																fontIcon="mdi-cancel"
																fontSet="mdi"></mat-icon>
															{{
																'MESSAGE_DELETED'
																	| translate
															}}
														</div>
													}
													@case (me.userId) {
														<div
															class="message-deleted">
															<mat-icon
																fontIcon="mdi-cancel"
																fontSet="mdi"></mat-icon>
															{{
																'MESSAGE_DELETED'
																	| translate
															}}
														</div>
													}
													@default {
														<div>
															@switch (
																message?.messageType
															) {
																@case (
																	'IMAGE'
																) {
																	<div
																		class="image-message">
																		<img
																			[src]="
																				message.data
																			"
																			alt=""
																			width="140px" />
																	</div>
																}
																@case (
																	'AUDIO'
																) {
																	<div
																		class="audio-message">
																		<ft-audio-message
																			(played)="
																				audioMessagePlayed(
																					$event
																				)
																			"
																			[audioMessage]="
																				message
																			"></ft-audio-message>
																	</div>
																}
																@case (
																	'EMOJI'
																) {
																	<div
																		class="emoji-message"></div>
																}
																@case ('FILE') {
																	<div
																		class="file-message">
																		<div
																			class="fx-layout-row fx-content-space-between fx-items-center fx-gap-4">
																			<mat-icon
																				style="
																					color: darkslategrey;
																				"
																				fontSet="mdi"
																				fontIcon="mdi-file"></mat-icon>
																			<span
																				>{{
																					message.text
																				}}</span
																			>
																		</div>
																	</div>
																}
																@case (
																	'VIDEO'
																) {
																	<div
																		class="video-message"></div>
																}
																@default {
																	<div
																		class="text-message fx-layout-row fx-content-start fx-items-center">
																		{{
																			message.text
																		}}
																	</div>
																}
															}
														</div>
													}
												}

												@if (
													message.messageType !==
													'AUDIO'
												) {
													<div class="time">
														{{
															message.sentDateTime
																| date: 'HH:mm'
														}}

														@if (
															messageIsMine(
																message
															)
														) {
															<mat-icon
																[class.seen]="
																	message.seenDateTime
																"
																[fontIcon]="
																	message.seenDateTime
																		? 'mdi-check-all'
																		: 'mdi-check'
																"
																class="message-status"
																fontSet="mdi"></mat-icon>
														}
													</div>
												}
											</div>
										</mat-checkbox>
									} @else {
										<div
											[class.right]="
												messageIsMine(message)
											"
											class="message">
											<div
												[class.left]="
													!messageIsMine(message)
												"
												[class.right]="
													messageIsMine(message)
												"
												class="tick"></div>

											@if (
												message.replyTo &&
												!message.deleted
											) {
												<div
													[style.borderLeftColor]="
														'#35cd96'
													"
													class="reply-to-msg fx-layout-row-nowrap fx-content-start fx-gap-4">
													<div
														[class.horizontal]="
															message.replyTo
																.messageType !==
															'TEXT'
														">
														<div
															[style.color]="
																'#34b7f1'
															">
															<span
																[style.fontWeight]="
																	'bold'
																"
																>{{
																	message
																		.replyTo
																		.sender
																		.id ===
																	me.id
																		? ('Me'
																			| translate)
																		: message
																				.replyTo
																				.sender
																				.name
																}}</span
															>

															@if (
																message.replyTo
																	.messageType !==
																'TEXT'
															) {
																<div
																	class="msg-type">
																	<mat-icon
																		[fontIcon]="
																			getIcon(
																				message
																					.replyTo
																					.messageType
																			)
																		"
																		fontSet="mdi"></mat-icon>

																	@switch (
																		message
																			.replyTo
																			.messageType
																	) {
																		@case (
																			'AUDIO'
																		) {
																			<span
																				>{{
																					getAudioDuration(
																						message
																							.replyTo
																							.duration
																					)
																				}}</span
																			>
																		}
																		@default {
																			<span
																				>{{
																					message
																						.replyTo
																						.text
																				}}</span
																			>
																		}
																	}
																</div>
															}
														</div>

														@switch (
															message.replyTo
																.messageType
														) {
															@case ('IMAGE') {
																<div
																	(click)="
																		openImage(
																			message.replyTo
																		)
																	"
																	class="image-message">
																	<img
																		[src]="
																			message
																				.replyTo
																				.data
																		"
																		alt=""
																		height="40px"
																		width="40px" />
																</div>
															}
															@case ('AUDIO') {
																<div
																	class="audio-message"></div>
															}
															@case ('EMOJI') {
																<div
																	class="emoji-message"></div>
															}
															@case ('FILE') {
																<div
																	class="file-message"></div>
															}
															@case ('VIDEO') {
																<div
																	class="video-message"></div>
															}
															@default {
																<div
																	class="text-message fx-layout-row fx-content-start fx-items-center">
																	{{
																		message
																			.replyTo
																			.text
																	}}
																</div>
															}
														}
													</div>
												</div>
											}

											@switch (message.deletedFor) {
												@case ('ALL') {
													<div
														class="message-deleted">
														<mat-icon
															fontIcon="mdi-cancel"
															fontSet="mdi"></mat-icon>
														{{
															'MESSAGE_DELETED'
																| translate
														}}
													</div>
												}
												@case (me.userId) {
													<div
														class="message-deleted">
														<mat-icon
															fontIcon="mdi-cancel"
															fontSet="mdi"></mat-icon>
														{{
															'MESSAGE_DELETED'
																| translate
														}}
													</div>
												}
												@default {
													<div>
														@switch (
															message?.messageType
														) {
															@case ('IMAGE') {
																<div
																	(click)="
																		openImage(
																			message
																		)
																	"
																	class="image-message">
																	<img
																		[src]="
																			message.data
																		"
																		alt=""
																		width="140px" />
																</div>
															}
															@case ('AUDIO') {
																<div
																	class="audio-message">
																	<ft-audio-message
																		(played)="
																			audioMessagePlayed(
																				$event
																			)
																		"
																		[audioMessage]="
																			message
																		"></ft-audio-message>
																</div>
															}
															@case ('EMOJI') {
																<div
																	class="emoji-message"></div>
															}
															@case ('FILE') {
																<div
																	class="file-message">
																	<div
																		class="fx-layout-row fx-content-space-between fx-items-center fx-gap-4">
																		<mat-icon
																			style="
																				color: darkslategrey;
																			"
																			fontSet="mdi"
																			fontIcon="mdi-file"></mat-icon>
																		<span>{{
																			message.text
																		}}</span>
																		<button
																			mat-icon-button
																			(click)="
																				downloadFile(
																					message
																				)
																			">
																			<mat-icon
																				fontIcon="mdi-download"
																				fontSet="mdi"></mat-icon>
																		</button>
																	</div>
																</div>
															}
															@case ('VIDEO') {
																<div
																	class="video-message"></div>
															}
															@default {
																<div
																	[innerHTML]="
																		message.text
																			| emojisDisplay
																	"
																	class="text-message fx-layout-row fx-content-start fx-items-center"></div>
															}
														}
													</div>
												}
											}

											@if (
												message.messageType !== 'AUDIO'
											) {
												<div class="time">
													{{
														message.sentDateTime
															| date: 'HH:mm'
													}}

													@if (
														messageIsMine(message)
													) {
														<mat-icon
															[class.seen]="
																message.seenDateTime
															"
															[fontIcon]="
																message.seenDateTime
																	? 'mdi-check-all'
																	: 'mdi-check'
															"
															class="message-status"
															fontSet="mdi"></mat-icon>
													}
												</div>
											}
										</div>
									}

									@if (
										!message.deleted && !selectionActivated
									) {
										<button
											(click)="replyToMessage = message"
											class="reply-btn fx-self-end"
											[class.fx-order--1]="
												message.sender?.id === me.id
											"
											[class.fx-order-1]="
												message.sender?.id !== me.id
											"
											mat-icon-button>
											<mat-icon
												fontIcon="mdi-reply"
												fontSet="mdi"></mat-icon>
										</button>
									}

									@if (
										!message.deleted && !selectionActivated
									) {
										<button
											[matMenuTriggerFor]="menu"
											class="reply-btn fx-self-end"
											[class.fx-order--2]="
												messageIsMine(message)
											"
											[class.fx-order-2]="
												!messageIsMine(message)
											"
											mat-icon-button>
											<mat-icon
												fontIcon="mdi-dots-horizontal"
												fontSet="mdi"></mat-icon>
										</button>
									}

									<mat-menu
										#menu="matMenu"
										overlapTrigger="true">
										<button
											(click)="forwardMessage(message)"
											mat-menu-item>
											<mat-icon
												fontIcon="mdi-share-outline"
												fontSet="mdi"></mat-icon>
											{{ 'FORWARD' | translate }}
										</button>
										<button
											(click)="deleteMessage(message)"
											mat-menu-item
											style="color: red">
											<mat-icon
												fontIcon="mdi-delete"
												fontSet="mdi"></mat-icon>
											{{ 'DELETE' | translate }}
										</button>
									</mat-menu>
								</div>
							}
						</div>
					}

					<div id="bottom"></div>
				</div>

				<!-- Progress -->
				@if (uploadProgress) {
					<div
						class="progress fx-layout-row fx-content-start fx-items-center fx-gap-4 fx-self-center">
						<mat-progress-bar
							color="accent"
							[value]="uploadProgress"
							class="progress-bar fx-grow-1"
							mode="determinate"></mat-progress-bar>
						<button
							(click)="cancelUpload()"
							[matTooltip]="'CANCEL' | translate"
							mat-icon-button>
							<mat-icon
								class="cancel-upload"
								fontIcon="mdi-close"
								fontSet="mdi"></mat-icon>
						</button>
					</div>
				}

				@if (replyToMessage) {
					<div
						[style.borderLeftColor]="replyToMessage.sender.color"
						style="
							margin: 4px 48px !important;
							padding: 12px !important;
						"
						class="reply-to-msg fx-layout-row-nowrap fx-content-start fx-gap-4">
						<div>
							<div
								[class.horizontal]="
									replyToMessage.messageType !== 'TEXT'
								">
								<div [style.color]="'#34b7f1'">
									<span [style.fontWeight]="'bold'">{{
										replyToMessage.sender.id === me.id
											? ('Me' | translate)
											: replyToMessage.sender.name
									}}</span>
									@if (
										replyToMessage.messageType !== 'TEXT'
									) {
										<div class="msg-type">
											<mat-icon
												[fontIcon]="
													getIcon(
														replyToMessage.messageType
													)
												"
												fontSet="mdi"></mat-icon>
											@if (
												replyToMessage.messageType ===
												'AUDIO'
											) {
												<span>{{
													getAudioDuration(
														replyToMessage.duration
													)
												}}</span>
											} @else {
												#otherRef|
											}
											<ng-template #otherRef>
												<span
													>•
													{{
														replyToMessage.messageType
															| translate
													}}</span
												>
											</ng-template>
										</div>
									}
								</div>

								@switch (replyToMessage.messageType) {
									@case ('IMAGE') {
										<div
											(click)="openImage(replyToMessage)"
											class="image-message">
											<img
												[src]="replyToMessage.data"
												alt=""
												height="40px"
												width="40px" />
										</div>
									}
									@case ('AUDIO') {
										<div class="audio-message"></div>
									}
									@case ('EMOJI') {
										<div class="emoji-message"></div>
									}
									@case ('FILE') {
										<div class="file-message"></div>
									}
									@case ('VIDEO') {
										<div class="video-message"></div>
									}
									@default {
										<div
											[innerHTML]="
												replyToMessage.text
													| emojisDisplay
											"
											class="text-message fx-layout-row fx-content-start fx-items-center"></div>
									}
								}
							</div>
						</div>
						<span class="fx-grow-1"></span>
						<button
							(click)="replyToMessage = null"
							color="warn"
							mat-icon-button>
							<mat-icon
								fontIcon="mdi-close"
								fontSet="mdi"></mat-icon>
						</button>
					</div>
				}

				@if (showEmojis) {
					<div
						class="emojis-container fx-layout-row fx-gap-8 relative fx-bg-transparent">
						<div style="width: 100%; text-align: end">
							<button
								(click)="showEmojis = false"
								mat-icon-button>
								<mat-icon
									fontIcon="mdi-close"
									fontSet="mdi"></mat-icon>
							</button>
						</div>
						@for (emoji of emojis; track emoji) {
							<img
								(click)="selectEmoji(emoji)"
								[alt]="emoji"
								[src]="emoji"
								class="emoji" />
						}
					</div>
				}

				<!--        selection actions-->
				@if (selectionActivated) {
					<div
						class="selection-actions fx-height-48 fx-layout-row-nowrap fx-content-start fx-items-center fx-gap-8">
						{{ selectedMessages.length }}
						{{
							(selectedMessages.length > 1
								? 'MESSAGES_SELECTED'
								: 'MESSAGE_SELECTED'
							) | translate
						}}
						<span class="fx-grow-1"></span>
						<button
							(click)="deleteSelection()"
							[disabled]="selectedMessages.length === 0"
							color="warn"
							mat-button>
							<mat-icon
								fontIcon="mdi-delete"
								fontSet="mdi"></mat-icon>
							{{ 'DELETE' | translate }}
						</button>
						<button
							(click)="forwardSelection()"
							[disabled]="selectedMessages.length === 0"
							mat-button>
							<mat-icon
								fontIcon="mdi-share-outline"
								fontSet="mdi"></mat-icon>
							{{ 'FORWARD' | translate }}
						</button>
						<button
							(click)="selectionActivated = false"
							mat-icon-button>
							<mat-icon
								fontIcon="mdi-close"
								fontSet="mdi"></mat-icon>
						</button>
					</div>
				} @else {
					<!--        Input and buttons-->
					<div
						class="fx-height-48 fx-layout-row fx-content-start fx-items-center fx-gap-12"
						style="z-index: 2">
						<button
							(click)="fileUpload.click()"
							[matTooltip]="'ATTACH_FILE' | translate"
							mat-icon-button>
							<mat-icon
								[style.color]="'#03A9F4'"
								fontIcon="mdi-paperclip"
								fontSet="mdi"></mat-icon>
						</button>
						<button
							(click)="showEmojis = !showEmojis"
							mat-icon-button>
							<mat-icon
								[style.color]="'#607D8B'"
								fontIcon="mdi-emoticon-happy-outline"
								fontSet="mdi"></mat-icon>
						</button>

						<div
							#message_box
							(keyup)="sendInput($event)"
							class="div-message fx-layout-row fx-content-start fx-items-center fx-gap-4 fx-grow-1 fx-height-16"
							contenteditable="true"
							data-placeholder="Message"></div>

						@if (!recordingActivated) {
							<button
								(click)="recordingActivated = true"
								[matTooltip]="'RECORD_AUDIO' | translate"
								mat-icon-button>
								<mat-icon
									[style.color]="'#E91E63'"
									fontIcon="mdi-microphone"
									fontSet="mdi"></mat-icon>
							</button>
							<button
								(click)="sendOnClick(message_box.innerHTML)"
								[matTooltip]="'SEND' | translate"
								mat-icon-button>
								<mat-icon
									[style.color]="'#009688'"
									fontIcon="mdi-send"
									fontSet="mdi"></mat-icon>
							</button>
						} @else {
							<ft-message-recorder
								(closeEvent)="recordingActivated = false"
								(confirmEvent)="
									onRecordFinish($event)
								"></ft-message-recorder>
						}
					</div>
				}
			</div>
		} @else {
			<div
				[style.height.%]="100"
				class="fx-grow-1 fx-layout-column fx-content-center fx-items-center">
				<button (click)="newMessage()" color="primary" mat-button>
					<mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
					{{ 'NEW_CHAT' | translate }}
				</button>
			</div>
		}
	</div>
</div>

@if (selectedImage) {
	<div
		class="image-previewer fx-layout-column fx-content-center fx-items-center fx-gap-4">
		<div
			class="fx-layout-row-nowrap fx-content-end fx-items-start fx-gap-8">
			<button
				(click)="selectedImage = null"
				[matTooltip]="'CLOSE' | translate"
				class="close"
				color="warn"
				mat-icon-button>
				<mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
			</button>
			<button
				(click)="saveImage(selectedImage)"
				[matTooltip]="'DOWNLOAD' | translate"
				class="download"
				color="primary"
				mat-icon-button>
				<mat-icon
					fontIcon="mdi-cloud-download"
					fontSet="mdi"></mat-icon>
			</button>
		</div>
		<img
			[alt]="selectedImage.text"
			[src]="selectedImage.data"
			style="max-height: 90%; max-width: 100%" />
	</div>
}
