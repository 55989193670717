import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MessagingComponent } from './messaging.component';

const routes: Routes = [
	{
		path: '',
		component: MessagingComponent,
		children: [
			{ path: '', pathMatch: 'full', redirectTo: 'messaging' },
			{ path: 'messaging', component: MessagingComponent },
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class MessagingRoutingModule {}
