import {
	AfterViewInit,
	Component,
	EventEmitter,
	inject,
	OnDestroy,
	Output,
} from '@angular/core';
import { get, isNil, noop } from 'lodash';
import { formatTimer } from '../../utils';
import { MatSnackBar } from '@angular/material/snack-bar';

declare let window: any;

@Component({
	selector: 'ft-message-recorder',
	template: ` <div
		class="fx-layout-row-nowrap fx-content-center fx-items-center fx-gap-16">
		<button
			[disabled]="!record"
			(click)="close()"
			class="cancel-record"
			color="warn"
			mat-icon-button>
			<mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
		</button>
		<div
			class="record-time fx-layout-row fx-content-center fx-items-center fx-gap-4">
			<mat-icon fontIcon="mdi-circle" fontSet="mdi"></mat-icon>
			<span>{{ formatTimer(timer) }}</span>
		</div>
		<button
			[disabled]="!record"
			(click)="confirm()"
			class="confirm-record"
			color="primary"
			mat-icon-button>
			<mat-icon fontSet="mdi" fontIcon="mdi-check"></mat-icon>
		</button>
	</div>`,
	styles: [
		`
			.cancel-record {
				border: 1px solid;
				transition: all 160ms ease-in-out;
			}

			.cancel-record:hover {
				background: #f44336;
				color: white;
				border: none;
				transform: scale(1.2);
			}

			.record-time span {
				color: gray;
			}

			.record-time mat-icon {
				font-size: 13px !important;
				color: #c70f0f;
				line-height: 21px;
				width: 16px !important;
				animation: colorAnimate infinite 1.4s ease-in-out;
			}

			.confirm-record {
				border: 1px solid;
				transition: all 160ms ease-in-out;
			}

			.confirm-record:hover {
				background: #2196f3;
				color: white;
				border: none;
				transform: scale(1.2);
			}

			@keyframes colorAnimate {
				0% {
					opacity: 0.1;
				}
				50% {
					opacity: 1;
				}
				100% {
					opacity: 0.1;
				}
			}
		`,
	],
})
export class MessageRecorderComponent implements AfterViewInit, OnDestroy {
	@Output() closeEvent = new EventEmitter<any>();
	@Output() confirmEvent = new EventEmitter<any>();
	public error: boolean;
	public timer = 0;
	public record = null;
	private interval: any;
	private stream: MediaStream;
	private recordData: { blob?: Blob; duration?: number } = {};
	private _snack = inject(MatSnackBar);

	formatTimer = (timer: number) => formatTimer(timer);

	ngAfterViewInit() {
		const getUserMedia = get(
			window,
			'navigator.mediaDevices.getUserMedia',
			null
		);
		const mediaRecorder = get(window, 'MediaRecorder', null);

		if (!isNil(getUserMedia) && !isNil(mediaRecorder)) {
			window.navigator['mediaDevices']['getUserMedia']({ audio: true })
				.then(
					stream => this.handleAudioStream(stream),
					_ => (this.error = true)
				);
		} else console.log('MediaRecord not supported');

		setTimeout(() => this.startRecording(), 1000);
	}

	close() {
		this.closeEvent.emit();
		isNil(this.stream)
			? noop()
			: this.stream['getAudioTracks']().forEach(track => track.stop());
		this.interval = null;
	}

	confirm() {
		if (this.record.state !== 'inactive') this.record.stop();
		setTimeout(() => {
			this.confirmEvent.emit(this.recordData);
			this.close();
		});
	}

	ngOnDestroy() {
		isNil(this.stream)
			? noop()
			: this.stream['getAudioTracks']().forEach(track => track.stop());
	}

	private handleAudioStream(stream: MediaStream) {
		this.stream = stream;
		this.record = new window.MediaRecorder(stream);
		this.record.ondataavailable = e => {
			this.recordData = {
				blob: new Blob([e.data], { type: 'audio/ogg' }),
				duration: this.timer,
			};
		};
	}

	private startRecording() {
		if (this.record === null) {
			this._snack.open('Media not supported', '', {
				duration: 2000,
				horizontalPosition: 'end',
			});
			return;
		}

		if (this.record.state === 'inactive') this.record.start();

		this.startTimer();
	}

	private startTimer() {
		this.interval = setInterval(() => {
			this.timer += 1;
		}, 10);
	}
}
