import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'ft-delete-option',
	template: ` <div
		class="fx-layout-column fx-content-center fx-items-start fx-gap-8"
		[style.padding.px]="16">
		<button
			color="primary"
			tabindex="-1"
			mat-button
			[matDialogClose]="'ME'">
			{{ 'DELETE_FOR_ME' | translate }}
		</button>
		@if (data['canDeleteForEveryone']) {
			<button
				color="primary"
				tabindex="-1"
				mat-button
				[matDialogClose]="'ALL'">
				{{ 'DELETE_FOR_EVERYONE' | translate }}
			</button>
		}
		<button mat-button [matDialogClose]="null">
			{{ 'CANCEL' | translate }}
		</button>
	</div>`,
	styles: [],
})
export class DeleteOptionComponent {
	public data = inject(MAT_DIALOG_DATA);
}
