<mat-toolbar class="fx-height-42" color="primary">
	<mat-icon fontSet="mdi" fontIcon="mdi-account-box"></mat-icon>
	<h3 style="padding-left: 4px">{{ 'CONTACTS' | translate }}</h3>
	<span class="fx-grow-1"></span>
	<button mat-icon-button matDialogClose="null" tabindex="-1">
		<mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
	</button>
</mat-toolbar>
<div class="fx-padding-8">
	<mat-selection-list [multiple]="source !== 'NEW'">
		@for (contact of contacts; track contact.id) {
			<mat-list-option
				(selectedChange)="selectContacts(contact)"
				[checkboxPosition]="'after'"
				class="fx-height-48">
				<div
					[style.background]="contact.color"
					matListItemAvatar
					class="fx-layout-row fx-content-center fx-items-center">
					{{ contact.name | initials }}
				</div>
				<div matListItemTitle>{{ contact.name }}</div>
			</mat-list-option>
		} @empty {
			<div class="fx-layout-row fx-content-center fx-items-center">
				<mat-spinner
					[diameter]="20"
					[strokeWidth]="2"
					color="warn"></mat-spinner>
			</div>
		}
	</mat-selection-list>
</div>
<div matDialogActions>
	<span class="fx-grow-1"></span>
	<button color="warn" mat-button [matDialogClose]="null">
		{{ 'CLOSE' | translate }}
	</button>
	<button
		(click)="ok()"
		[disabled]="selection.length === 0"
		color="primary"
		mat-button>
		{{ 'OK' | translate }}
	</button>
</div>
